import { createApp } from 'vue'
import App from './App.vue'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {AWSIoTProvider} from '@aws-amplify/pubsub';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-east-2',
  aws_pubsub_endpoint: 'wss://a2t9mh909xd8ba-ats.iot.us-east-2.amazonaws.com/mqtt',
}));

createApp(App).mount('#app')
